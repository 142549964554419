<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <div class="row justify-content-md-center">
      <div class="col-12">
        <filter-side-bar>
          <template slot="ContentSideBar">
            <!-- Filtros -->
            <h5 class="mb-0">Filtrar por:</h5>
            <form id="report-statement-weekly-list-filter" v-on:submit.prevent="applyFilter()">

              <!-- Período -->
              <h6 class="mb-3 mt-5">{{ $t('kpi.volume.period') }}</h6>
              <div class="row">
                <div class="col-md-12">
                  <div class="md-form mb-0 row">
                    <div class="col-md-6">
                        <div class="md-form mb-0">
                          <select class="md-form-control" :value="filters.filYear" id="year" name="year" ref="year" >
                            <option v-for="(item, index) in allData.years" v-bind:key="index" :value="item" :selected="filters.filYear === item" >{{ item }}</option>
                          </select>
                          <label :class="{'active': true}">{{ $t('form.report.year') }}</label>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="py-1 text-center">
                <div class="md-form mb-0">
                  <div class="row">
                    <div class="col-6">
                      <button type="submit" class="btn btn-primary btn-fill btn-block mr-2 ">{{ $t('filter.apply') }}</button>
                    </div>
                    <div class="col-6">
                      <button type="button"  v-on:click="resetFilter" class="btn btn-cancel btn-fill btn-block mr-2 ">{{ $t('filter.reset') }}</button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </template>
        </filter-side-bar>
      </div>

      <!-- Bloco com os Filtros -->
      <div class="col-12 mb-3">
        <filter-container :activeFilterList="getActiveFilterList" />
      </div>

      <!-- Percentual de Finalizados -->
      <div class="col-12 col-md-12">
        <div class="card card-fullheight">
          <div class="card-body">
            <h5 class="box-title mb-5">{{ $t('Percentual de Finalizados') }}</h5>
                  <table class="table">
                    <thead>
                    <th v-for="(value, index) in reportData.percentual_recebimento" v-bind:key="index" class="text-center">
                      Q{{ index }}
                    </th>
                    </thead>
                    <tbody>
                    <tr>
                      <td v-for="(value, index) in reportData.percentual_recebimento" v-bind:key="index" class="text-center">

                        {{ numberFormat((value) , 2, ',', '.') }}
                      </td>
                    </tr>
                    </tbody>
                  </table>
          </div>
        </div>
      </div>

      <!-- Situacao por quarter -->
      <div class="col-12 col-md-12" v-show="!(filters.filYear > 2021)">
        <div class="card card-fullheight">
          <div class="card-body">
<!--            <div class="ocultado">-->
<!--              <table class="table table-striped">-->
<!--                <thead>-->
<!--                <tr>-->
<!--                  <td>Mês</td>-->
<!--                  <td>Lucro Líquido</td>-->
<!--                  <td>Percentual Finalizados</td>-->
<!--                </tr>-->
<!--                </thead>-->
<!--                <tbody>-->
<!--                  <tr v-for="mes in reportData.rentabilidadeMes" v-bind:key="mes.mes">-->
<!--                    <td>-->
<!--                      {{ mes.mes }}-->
<!--                    </td>-->
<!--                    <td>-->
<!--                      {{ numberFormat(mes.lucro_liquido, 2) }}-->
<!--                    </td>-->
<!--                    <td>-->
<!--                      {{ numberFormat(mes.percentual_finalizados, 2) }}%-->
<!--                    </td>-->
<!--                  </tr>-->
<!--                </tbody>-->
<!--              </table>-->
<!--            </div>-->

<!--            <div class="ocultado">-->
<!--              <table class="table table-striped">-->
<!--                <thead>-->
<!--                <tr>-->
<!--                  <td>Mês</td>-->
<!--                  <td>Lucro Líquido (Estimado)</td>-->
<!--                  <td>Percentual Finalizados(Estimado)</td>-->
<!--                </tr>-->
<!--                </thead>-->
<!--                <tbody>-->
<!--                <tr v-for="mes in reportData.rentabilidadeEstimadaMes" v-bind:key="mes.mes">-->
<!--                  <td>-->
<!--                    {{ mes.mes }}-->
<!--                  </td>-->
<!--                  <td>-->
<!--                    {{ numberFormat(mes.lucro_liquido, 2) }}-->
<!--                  </td>-->
<!--                  <td>-->
<!--                    {{ numberFormat(mes.percentual_finalizados, 2) }}%-->
<!--                  </td>-->
<!--                </tr>-->
<!--                </tbody>-->
<!--              </table>-->
<!--            </div>-->

            <h5 class="box-title mb-5">{{ $t('Situação por quarter') }}</h5>
            <div class="row">
              <div class="col-sm-12 col-md-6 pb-5" v-for="(quarter, index) in reportData.period" v-bind:key="index">
                <h5 class="text-center"> Q{{ index }}</h5>

<!--                <div class="ocultado">-->
<!--                  Percentual: {{ numberFormat((quarter.percent > 0.0) ? quarter.percent : 0.0 , 2, ',', '.') }}% <br/>-->
<!--                  Percentual (Estimado): {{ numberFormat((quarter.estimated_percent > 0.0) ? quarter.estimated_percent : 0.0 , 2, ',', '.') }}% <br/>-->
<!--                  Meta: {{ numberFormat((quarter.goal > 0.0) ? quarter.goal : 0.0 , 2, ',', '.') }} <br/>-->
<!--                  Valor Atual: {{ numberFormat(quarter.value, 2, ',', '.') }} <br/>-->
<!--                  Valor (Estimado): {{ numberFormat(quarter.estimated_value, 2, ',', '.') }} <br/>-->
<!--                </div>-->

                <div class="row">
                  <div class="col-12">
                    <multiple-gauge-chart :value="quarter.percent" :indicator="quarter.estimated_percent" />
                  </div>
                </div>
                <div class="row justify-content-sm-center">
                  <div class="mr-3">
                    <span class="badge-point rounded-circle mr-1" style="background-color: #f04922;"></span>
                    &lt; 75%
                  </div>
                  <div class="mr-3">
                    <span class="badge-point rounded-circle mr-1" style="background-color: #f3eb0c;"></span>
                    75% até 99,99%
                  </div>
                  <div class="mr-3">
                    <span class="badge-point rounded-circle mr-1" style="background-color: #0f9747;"></span>
                    META ALCANÇADA
                  </div>
                </div>
                <div class="row justify-content-sm-center">
                  <div class="mr-3 ">
                    <span class="align-middle pointer-icon">
                      Realizado
                    </span>
                  </div>
                  <div class="mr-3">
                    <span class="align-middle indicator-icon">
                      Previsto
                    </span>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Situacao por ano -->
      <div class="col-12 col-md-12" v-show="!(filters.filYear > 2021)">
        <div class="card card-fullheight">
          <div class="card-body">
            <h5 class="box-title mb-5">{{ $t('Situação por ano') }}</h5>
            <div class="row">
              <div class="col-sm-12 col-md-3">
              </div>
              <div class="col-sm-12 col-md-6">
<!--                <div class="ocultado">-->
<!--                  Percentual: {{ numberFormat((reportData.ano.percent > 0.0) ? reportData.ano.percent : 0.0 , 2, ',', '.') }}% <br/>-->
<!--                  Percentual( Estimado): {{ numberFormat((reportData.ano.estimated_percent > 0.0) ? reportData.ano.estimated_percent : 0.0 , 2, ',', '.') }}% <br/>-->
<!--                  Meta: {{ numberFormat((reportData.ano.goalValue > 0.0) ? reportData.ano.goalValue : 0.0 , 2, ',', '.') }} <br/>-->
<!--                  Valor Atual: {{ numberFormat(reportData.ano.value, 2, ',', '.') }} <br/>-->
<!--                  Valor (Estimado): {{ numberFormat(reportData.ano.estimated_value, 2, ',', '.') }} <br/>-->
<!--                  <br/>-->
<!--                </div>-->
                <multiple-gauge-chart  :ranges="ranges" :chartMax="250.0" :indicator="reportData.ano.estimated_percent"  :value="reportData.ano.percent" :max-percent-show="200.0" />
              </div>
              <div class="col-sm-12">
              <div class="row justify-content-sm-center">
                <div class="mr-3">
                  <span class="badge-point rounded-circle mr-1" style="background-color: #f04922;"></span>
                  &lt; 125%
                </div>
                <div class="mr-3">
                  <span class="badge-point rounded-circle mr-1" style="background-color: #b0d136;"></span>
                  125% até 149,99%
                </div>
                <div class="mr-3">
                  <span class="badge-point rounded-circle mr-1" style="background-color: #54b947;"></span>
                  150% até 200%
                </div>
                <div class="mr-3">
                  <span class="badge-point rounded-circle mr-1" style="background-color: #0f9747;"></span>
                  > 200%
                </div>
              </div>
                <div class="row justify-content-sm-center">
                  <div class="mr-3 ">
                    <span class="align-middle pointer-icon">
                      Realizado
                    </span>
                  </div>
                  <div class="mr-3">
                    <span class="align-middle indicator-icon">
                      Previsto
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
// eslint-disable-next-line
import { util } from '@/utils/utils.js'

// Components
import FilterSideBar from '@/components/FilterSideBar.vue'
import FilterContainer from '@/components/FilterContainer'
import MultipleGaugeChart from '@/components/Chart/amcharts4/MultipleGaugeChart.vue'

// Loading
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// DatePicker
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

// Services
import ReportService from '@/services/ReportService'

export default {
  name: 'StatementCommercialWeekly',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('report.title') + ' ' + this.$i18n.t('report.statement.commercial.title') + ' - %s'
    }
  },
  data () {
    return {
      tempPeriod: 'yearly',
      allData: [],
      ranges: [
        {
          color: '#f04922',
          lowScore: 0.0,
          highScore: 124.99
        },
        {
          color: '#b0d136',
          lowScore: 125,
          highScore: 149.99
        },
        {
          color: '#54b947',
          lowScore: 150,
          highScore: 199.99
        },
        {
          color: '#0f9747',
          lowScore: 200,
          highScore: 250.00
        }
      ],
      operational: [],
      reportData: {
        percentual_recebimento: {},
        period: {
          1: {
            goal: 0.0,
            value: 0.0,
            estimated_value: 0.0,
            percent: -1.0,
            estimated_percent: -1.0
          },
          2: {
            goal: 0.0,
            value: 0.0,
            estimated_value: 0.0,
            percent: -1.0,
            estimated_percent: -1.0
          },
          3: {
            goal: 0.0,
            value: 0.0,
            estimated_value: 0.0,
            percent: -1.0,
            estimated_percent: -1.0
          },
          4: {
            goal: 0.0,
            value: 0.0,
            estimated_value: 0.0,
            percent: -1.0,
            estimated_percent: -1.0
          }
        },
        ano: {
          percent: -1.0,
          estimated_percent: -1.0
        }
      },
      isLoading: true,
      fullPage: true,
      dataFilterFullMonth: [],
      dataFilterYear: [],
      dataFilterDate: [],
      activeFilterList: {
        year: null
      },
      filter: {}
    }
  },
  components: {
    FilterSideBar,
    Loading,
    FilterContainer,
    MultipleGaugeChart
  },
  beforeMount () {
    /**
     * Returns the week number for this date.  dowOffset is the day of week the week
     * "starts" on for your locale - it can be from 0 to 6. If dowOffset is 1 (Monday),
     * the week returned is the ISO 8601 week number.
     * @param int dowOffset
     * @return int
     */
    // eslint-disable-next-line
    Date.prototype.getWeek = function (dowOffset) {
      // default dowOffset to zero
      dowOffset = dowOffset !== undefined && typeof (dowOffset) === 'number' ? dowOffset : 0
      let newYear = new Date(this.getFullYear(), 0, 1)
      // the day of week the year begins on
      let day = newYear.getDay() - dowOffset
      day = (day >= 0 ? day : day + 7)
      let daynum = Math.floor((this.getTime() - newYear.getTime() -
        (this.getTimezoneOffset() - newYear.getTimezoneOffset()) * 60000) / 86400000) + 1
      let weeknum
      // if the year starts before the middle of a week
      if (day < 4) {
        weeknum = Math.floor((daynum + day - 1) / 7) + 1
        if (weeknum > 52) {
          let nYear = new Date(this.getFullYear() + 1, 0, 1)
          let nday = nYear.getDay() - dowOffset
          nday = nday >= 0 ? nday : nday + 7
          // if the next year starts before the middle of the week, it is week #1 of that year
          weeknum = nday < 4 ? 1 : 53
        }
      } else {
        weeknum = Math.floor((daynum + day - 1) / 7)
      }
      return weeknum
    }

    this.buildFilters()
  },
  mounted () {
    this.$store.dispatch('SetShowFilter', true)
    this.$store.dispatch('setShowSearch', false)

    this.getReportData()

    this.$jQuery('#begin_date, #end_date').datepicker({
      language: 'pt-BR',
      calendarWeeks: true,
      autoclose: true,
      templates: {
        leftArrow: '<i class="ti-angle-left"></i>',
        rightArrow: '<i class="ti-angle-right"></i>'
      }
    })
  },
  methods: {
    buildFilters () {
      this.filter.period = 'yearly'

      /**
       *  Não primeira abertura seta o filtros com o padrão
       *   - Mês: Atual
       *   - Ano: Atual
       *   - Colaborador: Usuário autenticado
       *  */

      let today = new Date()
      if (!this.filters.filYear && !this.filters.filWeek && !this.filters.filMonth) {
        this.filters.filYear = today.getFullYear()
        this.filters.filWeek = today.getWeek()
        this.filters.filMonth = today.getMonth() + 1
        this.$store.dispatch('setAppliedFilter', this.filters)
      }

      if ((!this.filters.filWeek)) {
        this.filters.filWeek = today.getWeek()
      }

      if ((!this.filters.filMonth)) {
        this.filters.filMonth = today.getMonth() + 1
      }

      this.filter.year = this.filters.filYear
      this.filter.week = this.filters.filWeek
      this.filter.month = this.filters.filMonth
    },
    // Filter
    applyFilter () {
      // Period
      this.filters.filPeriodo = 'yearly'

      // Filter Processes

      this.filters.filYear = this.$refs.year ? parseInt(this.$refs.year.value) : null
      this.filters.filWeek = this.$refs.week ? parseInt(this.$refs.week.value) : null
      this.filters.filMonth = this.$refs.month ? parseInt(this.$refs.month.value) : null

      this.buildFilters()

      this.$store.dispatch('setAppliedFilter', this.filters)

      this.hideQuichSidebar()
      this.getReportData()
    },
    resetFilter (event) {
      this.hideQuichSidebar()
      // hasFilter: false
      let today = new Date()

      this.filters.filYear = today.getFullYear()
      this.filters.filWeek = today.getWeek()
      this.filters.filMonth = today.getMonth() + 1
      this.filters.filPeriodo = 'yearly'

      this.$store.dispatch('setAppliedFilter', this.filters)

      this.buildFilters()

      this.getReportData()
    },
    hideQuichSidebar () {
      const sideBar = document.querySelector('#quick-sidebar')
      document.body.classList.remove('has-backdrop')
      sideBar.classList.remove('shined')
    },
    numberFormat (number, decimals, decPoint, thousandsSep) {
      return util.numberFormat(number, decimals, decPoint, thousandsSep)
    },
    // End Filter
    getReportData () {
      let me = this
      me.isLoading = true

      ReportService.getRentabilidade(this.filter).then(res => {
        me.reportData = res.data.reportData
        me.allData = res.data
        me.isLoading = false
      })
    }
  },
  computed: {
    chartData () {
      return {
        labels: ['A', 'B', 'C'],
        datasets: [{
          backgroundColor: '#2949ef',
          data: [12, 19, 3],
          fill: false
        }]
      }
    },
    myStyles () {
      return {
        height: `150px`,
        position: 'relative'
      }
    },
    getActiveFilterList () {
      let activedFilterList = {
        year: null
      }

      return activedFilterList
    },
    user () {
      return this.$store.getters.userInfo || null
    },
    filters () {
      if (this.$Cookie.get('_userStoredFilters_')) {
        let appliedFilters = JSON.parse(this.$Cookie.get('_userStoredFilters_'))

        if (appliedFilters.filYear < 2021) {
          appliedFilters.filYear = 2021
          this.$store.dispatch('setAppliedFilter', appliedFilters)
        }
        return appliedFilters
      } else {
        return null
      }
    },
    refreshFilter () {
      return this.$store.getters.getRefreshFilter || null
    }
  },
  watch: {
    user: function () {
      this.getReportData()
    },
    refreshFilter: function () {
      if (this.refreshFilter) {
        this.applyFilter()
        this.$store.dispatch('setRefreshFilter', false)
      }
    }
  }
}
</script>

<style scoped>

  .indicator-icon {
    background-image: url("/static/images/Indicator.png");
    background-repeat: no-repeat;
    background-position: left center;
    padding-left: 25px;
  }

  .pointer-icon {
    background-image: url("/static/images/ponteiro.png");
    background-repeat: no-repeat;
    background-position: left center;
    padding-left: 25px;
  }

  /*.ocultado {*/
  /*  margin-top: 10px;*/
  /*  padding: 20px;*/
  /*  border-left: 5px solid #aa6708;*/
  /*  background-color: #f0f0f0;*/
  /*}*/

  .badge-point.rounded-circle {
    height: 10px;
    width: 10px;
  }

</style>
