<template>
  <div class="col-12">
    <div :id="id" class="chartdiv" ref="chartdiv"></div>
  </div>
</template>

<script>
// Charts
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themesAnimated from '@amcharts/amcharts4/themes/animated'

am4core.useTheme(am4themesAnimated)

export default {
  name: 'multiple-gauge-chart',
  data () {
    return {
      id: null,
      chart: null,
      hand: null,
      hand2: null,
      polygonSeries: null,
      matchingGrade: null
    }
  },
  props: {
    maxPercentShow: {
      type: Number,
      required: false,
      default: 100.0
    },
    ranges: {
      type: Array,
      required: false,
      default: () => {
        return [
          {
            color: '#f04922',
            lowScore: 0.0,
            highScore: 74.99
          },
          {
            color: '#f3eb0c',
            lowScore: 75,
            highScore: 99.99
          },
          {
            color: '#0f9747',
            lowScore: 100.0,
            highScore: 120
          }
        ]
      }
    },
    chartMin: {
      type: Number,
      required: false,
      default: 0.0
    },
    chartMax: {
      type: Number,
      required: false,
      default: 120.0
    },
    value: {
      type: Number,
      required: true,
      default: () => {
        return 0.0
      }
    },
    indicator: {
      type: Number,
      required: true,
      default: () => {
        return 0.0
      }
    },
    colors: {
      type: Array,
      required: false,
      default: () => {
        return [
          '#664fa1',
          '#274ea2',
          '#3675bc',
          '#6acadb',
          '#69bd63',
          '#60ba47',
          '#8ec63d',
          '#f5ea03',
          '#f47a20',
          '#ed1b24',
          '#ea017a',
          '#b64b9b'
        ]
      }
    }
  },
  watch: {
    indicator (newVal, oldVal) {
      let val = this.getFormatedValue(newVal)
      this.hand2.showValue(val, 1000, am4core.ease.cubicOut)
    },
    value (newVal, oldVal) {
      let val = this.getFormatedValue(newVal)
      this.hand.showValue(val, 1000, am4core.ease.cubicOut)
    }
  },
  methods: {
    getMaxShowValue (val) {
      let _this = this

      if (val <= _this.chartMin) {
        return _this.chartMin
      }

      if (val >= _this.maxPercentShow) {
        return _this.maxPercentShow
      }

      return val
    },
    getFormatedValue (val) {
      let _this = this

      if (val <= _this.chartMin) {
        return _this.chartMin
      }

      if (val >= _this.chartMax) {
        return _this.chartMax
      }
      let b = val

      return b
    },
    lookUpGrade (lookupScore, grades) {
      // Only change code below this line
      for (var i = 0; i < grades.length; i++) {
        if (
          grades[i].lowScore < lookupScore &&
          grades[i].highScore >= lookupScore
        ) {
          return grades[i]
        }
      }
      return null
    }
  },
  created () {
    this.id = 'chart_' + this._uid
  },
  mounted () {
    let _this = this

    // Ranges
    let data = {
      score: _this.value,
      gradingData: _this.ranges
    }

    let chart = am4core.create(this.$refs.chartdiv, am4charts.GaugeChart)
    chart.hiddenState.properties.opacity = 0
    chart.fontSize = 11
    chart.innerRadius = am4core.percent(80)
    chart.resizable = true

    var axis = chart.xAxes.push(new am4charts.ValueAxis())
    axis.min = _this.chartMin
    axis.max = _this.chartMax
    axis.strictMinMax = true
    axis.renderer.inside = true
    axis.renderer.labels.template.disabled = true
    // axis.renderer.ticks.template.inside = true
    // axis.stroke = chart.colors.getIndex(3)
    axis.renderer.radius = am4core.percent(97)
    // axis.renderer.radius = 80
    axis.renderer.line.strokeOpacity = 1
    axis.renderer.line.strokeWidth = 5
    axis.renderer.line.stroke = chart.colors.getIndex(0)
    axis.renderer.ticks.template.disabled = false
    axis.renderer.ticks.template.stroke = chart.colors.getIndex(0)
    axis.renderer.labels.template.radius = 35
    axis.renderer.ticks.template.strokeOpacity = 1
    axis.renderer.grid.template.disabled = true
    axis.renderer.ticks.template.length = 10
    axis.hiddenState.properties.opacity = 1
    axis.hiddenState.properties.visible = true
    axis.setStateOnChildren = true
    axis.renderer.hiddenState.properties.endAngle = 180
    axis.renderer.grid.template.disabled = true

    // var axis2 = chart.xAxes.push(new am4charts.ValueAxis())
    // axis2.min = 0
    // axis2.max = 160
    // axis2.strictMinMax = true
    // // Oculta linhas do grid (Background)
    // axis2.renderer.grid.template.disabled = true
    // axis2.renderer.ticks.template.disabled = true
    // axis2.renderer.line.strokeWidth = 0
    // axis2.renderer.labels.template.disabled = false
    // axis2.renderer.ticks.template.disabled = true
    // axis2.renderer.labels.template.bent = true

    var axis2 = chart.xAxes.push(new am4charts.ValueAxis())
    axis2.min = _this.chartMin
    axis2.max = _this.chartMax
    axis2.strictMinMax = true
    axis2.renderer.labels.template.disabled = true
    axis2.renderer.ticks.template.disabled = true
    axis2.renderer.grid.template.disabled = false
    axis2.renderer.grid.template.opacity = 0.5
    axis2.renderer.labels.template.bent = true
    axis2.renderer.labels.template.fill = am4core.color('#000')
    axis2.renderer.labels.template.fontWeight = 'bold'
    axis2.renderer.labels.template.fillOpacity = 0.3

    /**
     Ranges
     */

    for (let grading of data.gradingData) {
      var range = axis2.axisRanges.create()
      range.axisFill.fill = am4core.color(grading.color)
      range.axisFill.fillOpacity = 0.8
      range.axisFill.zIndex = -1
      range.value = grading.lowScore > _this.chartMin ? grading.lowScore : _this.chartMin
      range.endValue = grading.highScore < _this.chartMax ? grading.highScore : _this.chartMax
      range.grid.strokeOpacity = 0
      range.stroke = am4core.color(grading.color).lighten(-0.1)
      range.label.disabled = true
    }

    _this.matchingGrade = this.lookUpGrade(data.score, data.gradingData)

    _this.hand = chart.hands.push(new am4charts.ClockHand())
    _this.hand.innerRadius = am4core.percent(35)
    _this.hand.fill = am4core.color('#333')
    _this.hand.stroke = am4core.color('#333')
    _this.hand.axis = axis
    _this.hand.pin.radius = 0
    _this.hand.startWidth = 5

    _this.hand2 = chart.hands.push(new am4charts.ClockHand())
    _this.hand2.fill = am4core.color('#333')
    _this.hand2.stroke = am4core.color('#333')
    _this.hand2.axis = axis2
    _this.hand2.pin.disabled = true
    // _this.hand2.pin.radius = 0
    _this.hand2.startWidth = 2
    _this.hand2.endWidth = 2
    _this.hand2.innerRadius = am4core.percent(80)
    _this.hand2.radius = am4core.percent(90)
    _this.hand2.startWidth = 8

    var label = chart.radarContainer.createChild(am4core.Label)
    label.isMeasured = false
    label.fontSize = '30px'
    label.x = am4core.percent(50)
    label.paddingBottom = 5
    label.horizontalCenter = 'middle'
    label.verticalCenter = 'bottom'
    // label.dataItem = data
    label.text = _this.getMaxShowValue(_this.value).toFixed(0) + '% / ' + _this.getMaxShowValue(_this.indicator).toFixed(0) + '%'

    _this.chart = chart

    _this.hand.showValue((_this.getFormatedValue(0)), 0, am4core.ease.cubicOut)
    _this.hand2.showValue((_this.getFormatedValue(0)), 0, am4core.ease.cubicOut)

    _this.hand.events.on('positionchanged', function () {
      _this.hand.showValue((_this.getFormatedValue(_this.getValue)), 1000, am4core.ease.cubicOut)
      label.text = _this.getMaxShowValue(_this.value).toFixed(0) + '% / ' + _this.getMaxShowValue(_this.indicator).toFixed(0) + '%'
    })

    _this.hand2.events.on('positionchanged', function () {
      _this.hand2.showValue((_this.getFormatedValue(_this.getIndicator)), 1000, am4core.ease.cubicOut)
      label.text = _this.getMaxShowValue(_this.value).toFixed(0) + '% / ' + _this.getMaxShowValue(_this.indicator).toFixed(0) + '%'
    })
  },
  beforeDestroy () {
    if (this.chart) {
      this.chart.dispose()
    }
  },
  computed: {
    getValue () {
      return this.getFormatedValue(this.value)
    },
    getIndicator () {
      return this.getFormatedValue(this.indicator)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .chartdiv {
    width: 100%;
    height: 300px;
  }

  .badge-point{
      height: 10px;
      width: 10px;
      margin-right: 6px;
  }
</style>
